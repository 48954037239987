// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tK9XcxYGcwuE_63Zyupj .WDpDDL9ZazgzYAtxY8li.oeSP3tkF0Chnzev0yDMG{font-weight:bold}.tK9XcxYGcwuE_63Zyupj .WDpDDL9ZazgzYAtxY8li:after{content:', '}.tK9XcxYGcwuE_63Zyupj .WDpDDL9ZazgzYAtxY8li:nth-last-child(2):after{content:' and '}.tK9XcxYGcwuE_63Zyupj .WDpDDL9ZazgzYAtxY8li:last-child:after{content:none}`, "",{"version":3,"sources":["webpack://./src/components/core/Sentence/index.styl"],"names":[],"mappings":"AAEI,iEACE,gBAAa,CAGf,kDACE,YAAS,CAGX,oEACE,eAAS,CAGX,6DACE,YAAS","sourcesContent":[".sentence {\n  .item {\n    &.current {\n      font-weight: bold\n    }\n\n    &:after {\n      content: ', '\n    }\n\n    &:nth-last-child(2):after {\n      content: ' and '\n    }\n\n    &:last-child:after {\n      content: none\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentence": `tK9XcxYGcwuE_63Zyupj`,
	"item": `WDpDDL9ZazgzYAtxY8li`,
	"current": `oeSP3tkF0Chnzev0yDMG`
};
export default ___CSS_LOADER_EXPORT___;
