// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nBOIgVvDC8qhbx1L5SaO{padding:3px}.kwE0Rg72yaVRXxZmFWYD{width:100%;padding-bottom:60%;border-radius:5px}.leaflet-container{background:repeating-linear-gradient(-45deg,#2f2f2f,#2f2f2f 5px,rgba(0,0,0,0.1) 5px,rgba(0,0,0,0.1) 10px)}`, "",{"version":3,"sources":["webpack://./src/components/core/GameMap/index.styl"],"names":[],"mappings":"AAAA,sBACE,WAAS,CAGX,sBACE,UAAO,CACP,kBAAgB,CAChB,iBAAe,CAGjB,mBACE,yGAAY","sourcesContent":[".box {\n  padding: 3px\n}\n\n.map {\n  width: 100%\n  padding-bottom: 60%\n  border-radius: 5px\n}\n\n:global(.leaflet-container) {\n  background: repeating-linear-gradient(\n    -45deg,\n    #2F2F2F,\n    #2F2F2F 5px,\n    rgba(0, 0, 0, .1) 5px,\n    rgba(0, 0, 0, .1) 10px\n  )\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `nBOIgVvDC8qhbx1L5SaO`,
	"map": `kwE0Rg72yaVRXxZmFWYD`
};
export default ___CSS_LOADER_EXPORT___;
