// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iWLd2Ix5aBo2TRDoPlE1{display:block;position:relative;padding:20px;border-radius:5px;background:#820000;border:1px solid #f00;color:#fff}.iWLd2Ix5aBo2TRDoPlE1 pre.FBy4AGpAaf5kswZVI9MJ{white-space:pre-line;padding-left:20px;color:#ccc}.iWLd2Ix5aBo2TRDoPlE1 > *:last-child{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/components/core/Error/index.styl"],"names":[],"mappings":"AAAA,sBACE,aAAS,CACT,iBAAU,CACV,YAAS,CACT,iBAAe,CACf,kBAAY,CACZ,qBAAQ,CACR,UAAO,CAEP,+CACE,oBAAa,CACb,iBAAc,CACd,UAAO,CAGT,qCACE,eAAe","sourcesContent":[".error {\n  display: block\n  position: relative\n  padding: 20px\n  border-radius: 5px\n  background: #820000\n  border: 1px solid #FF0000\n  color: #FFFFFF\n\n  pre.stack {\n    white-space: pre-line\n    padding-left: 20px\n    color: darken(#FFFFFF, 20%)\n  }\n\n  > *:last-child {\n    margin-bottom: 0\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `iWLd2Ix5aBo2TRDoPlE1`,
	"stack": `FBy4AGpAaf5kswZVI9MJ`
};
export default ___CSS_LOADER_EXPORT___;
