// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ne96o92fDoemWwCPnjgL{margin-right:.4em;margin-top:-2px;max-height:2em}.CnqM3yISKkwgvjU85SVm{height:100vh}`, "",{"version":3,"sources":["webpack://./src/components/images/GameIcon/index.styl"],"names":[],"mappings":"AAAA,sBACE,iBAAc,CACd,eAAY,CACZ,cAAY,CAGd,sBACE,YAAQ","sourcesContent":[".icon {\n  margin-right: 0.4em\n  margin-top: -2px\n  max-height: 2em\n}\n\n.asBackground {\n  height: 100vh\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `ne96o92fDoemWwCPnjgL`,
	"asBackground": `CnqM3yISKkwgvjU85SVm`
};
export default ___CSS_LOADER_EXPORT___;
