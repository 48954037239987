// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dN9XW41lgHHrMhZkNKgV,.dN9XW41lgHHrMhZkNKgV a{color:#42affe}.v9sXF2p1flXHJsEf3Uvv{margin-left:1px;color:#fff;font-size:1.3em;line-height:.5em;opacity:.4}`, "",{"version":3,"sources":["webpack://./src/components/entities/Quest/Reference/index.styl"],"names":[],"mappings":"AACE,8CACE,aAAO,CAIX,sBACE,eAAa,CACb,UAAO,CACP,eAAW,CACX,gBAAa,CACb,UAAS","sourcesContent":[".repeatable {\n  &, a {\n    color: #42AFFE\n  }\n}\n\n.chain {\n  margin-left: 1px\n  color: #FFF\n  font-size: 1.3em\n  line-height: .5em\n  opacity: .4\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"repeatable": `dN9XW41lgHHrMhZkNKgV`,
	"chain": `v9sXF2p1flXHJsEf3Uvv`
};
export default ___CSS_LOADER_EXPORT___;
