// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.yYSX01lIedEiZiOfDJvu,th.yYSX01lIedEiZiOfDJvu{width:1%;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/core/Table/columns/IDColumn/index.styl"],"names":[],"mappings":"AACE,gDACE,QAAO,CACP,iBAAY","sourcesContent":["td, th {\n  &.id {\n    width: 1%\n    text-align: center\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"id": `yYSX01lIedEiZiOfDJvu`
};
export default ___CSS_LOADER_EXPORT___;
