// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l81qwKElHM3ljj5EhiTv{color:#999;font-size:.8em;text-decoration:none !important}.l81qwKElHM3ljj5EhiTv:before{content:'<'}.l81qwKElHM3ljj5EhiTv:after{content:'>'}`, "",{"version":3,"sources":["webpack://./src/components/entities/NPC/Reference/index.styl"],"names":[],"mappings":"AAAA,sBACE,UAAO,CACP,cAAW,CACX,+BAAiB,CAEjB,6BACE,WAAS,CAGX,4BACE,WAAS","sourcesContent":[".subname {\n  color: #999999\n  font-size: .8em\n  text-decoration: none !important\n\n  &:before {\n    content: '<'\n  }\n\n  &:after {\n    content: '>'\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subname": `l81qwKElHM3ljj5EhiTv`
};
export default ___CSS_LOADER_EXPORT___;
