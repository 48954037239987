// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FKBpaYUZEw0YiW13VNbF{position:relative;font-size:.9em}.FKBpaYUZEw0YiW13VNbF ul{list-style-type:none;display:flex;height:1em;justify-content:left;align-items:center;padding:0;margin:15px 0}.FKBpaYUZEw0YiW13VNbF ul li a{padding:8px;color:#806600;cursor:pointer}.FKBpaYUZEw0YiW13VNbF ul li a.qba3B_UzLRdnOBGnWrAi{color:#fc0}.FKBpaYUZEw0YiW13VNbF ul li.Z1k7SUvoIoT5hL_YQ0RF{padding:5px;color:#666}.FKBpaYUZEw0YiW13VNbF .PFEfTA75Z1KdfYJX6Fd4{position:absolute;top:0;right:0;color:#666}* + .FKBpaYUZEw0YiW13VNbF ul{margin-top:25px}`, "",{"version":3,"sources":["webpack://./src/components/core/Pagination/index.styl"],"names":[],"mappings":"AAAA,sBACE,iBAAU,CACV,cAAW,CAEX,yBACE,oBAAiB,CACjB,YAAS,CACT,UAAQ,CACR,oBAAiB,CACjB,kBAAa,CACb,SAAS,CACT,aAAQ,CAGN,8BACE,WAAS,CACT,aAAO,CACP,cAAQ,CAER,mDACE,UAAO,CAIX,iDACE,WAAS,CACT,UAAO,CAKb,4CACE,iBAAU,CACV,KAAK,CACL,OAAO,CACP,UAAO,CAGT,6BACE,eAAY","sourcesContent":[".pagination {\n  position: relative\n  font-size: .9em\n\n  ul {\n    list-style-type: none\n    display: flex\n    height: 1em\n    justify-content: left\n    align-items: center\n    padding: 0\n    margin: 15px 0\n\n    li {\n      a {\n        padding: 8px\n        color: darken(#FFCC00, 50%)\n        cursor: pointer\n\n        &.active {\n          color: #FFCC00\n        }\n      }\n\n      &.spacer {\n        padding: 5px\n        color: darken(#FFFFFF, 60%)\n      }\n    }\n  }\n\n  .legend {\n    position: absolute\n    top: 0\n    right: 0\n    color: darken(#FFFFFF, 60%)\n  }\n\n  * + & ul {\n    margin-top: 25px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `FKBpaYUZEw0YiW13VNbF`,
	"active": `qba3B_UzLRdnOBGnWrAi`,
	"spacer": `Z1k7SUvoIoT5hL_YQ0RF`,
	"legend": `PFEfTA75Z1KdfYJX6Fd4`
};
export default ___CSS_LOADER_EXPORT___;
