// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eP0nq_2Bb41VaqBjZGpT{width:100%;border-collapse:collapse}.eP0nq_2Bb41VaqBjZGpT td,.eP0nq_2Bb41VaqBjZGpT th{padding:9px;border-top:1px solid #121212;box-shadow:inset 0 1px #2e2e2e;font-weight:normal}.eP0nq_2Bb41VaqBjZGpT th{text-align:left;font-weight:bold}.eP0nq_2Bb41VaqBjZGpT thead tr:first-child td,.eP0nq_2Bb41VaqBjZGpT thead tr:first-child th{border-top:none;box-shadow:none}.eP0nq_2Bb41VaqBjZGpT tbody tr:nth-child(odd) td,.eP0nq_2Bb41VaqBjZGpT tbody tr:nth-child(odd) th{background:#252525}`, "",{"version":3,"sources":["webpack://./src/components/core/Table/index.styl"],"names":[],"mappings":"AAAA,sBACE,UAAO,CACP,wBAAiB,CAEjB,kDACE,WAAS,CACT,4BAAY,CACZ,8BAAY,CACZ,kBAAa,CAGf,yBACE,eAAY,CACZ,gBAAa,CAIb,4FACE,eAAY,CACZ,eAAY,CAKd,kGACE,kBAAY","sourcesContent":[".table {\n  width: 100%\n  border-collapse: collapse\n\n  td, th {\n    padding: 9px\n    border-top: 1px solid #121212\n    box-shadow: inset 0 1px #2E2E2E\n    font-weight: normal\n  }\n\n  th {\n    text-align: left\n    font-weight: bold\n  }\n\n  thead tr:first-child {\n    td, th {\n      border-top: none\n      box-shadow: none\n    }\n  }\n\n  tbody tr:nth-child(odd) {\n    td, th {\n      background: #252525\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `eP0nq_2Bb41VaqBjZGpT`
};
export default ___CSS_LOADER_EXPORT___;
