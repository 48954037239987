// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.POL4Hpsi7czZn_M1yOTx{color:#9d9d9d !important}.KQ9O7TfrzXwceQgsA6tw{color:#fff !important}.a9WoVazYs4aLdZ3vM416{color:#1eff00 !important}.nPkziMFG4wNKXgGtpHpW{color:#0070dd !important}.spiZKbwyNh12LsxlNFqe{color:#a335ee !important}.DNT33FHVeD91KE0o_tDe{color:#ff8000 !important}.T5tTtTQ7QhKJf5BLr6Zx{color:#e5cc80 !important}.JPD6vHxWVsZ2MXSQp0U3{color:#0cf !important}`, "",{"version":3,"sources":["webpack://./src/components/entities/Item/Reference/index.styl"],"names":[],"mappings":"AAYE,sBACE,wBAAO,CADT,sBACE,qBAAO,CADT,sBACE,wBAAO,CADT,sBACE,wBAAO,CADT,sBACE,wBAAO,CADT,sBACE,wBAAO,CADT,sBACE,wBAAO,CADT,sBACE,qBAAO","sourcesContent":["$qualities = {\n  poor: #9D9D9D\n  common: #FFFFFF\n  uncommon: #1EFF00\n  rare: #0070DD\n  epic: #A335EE\n  legendary: #FF8000\n  artifact: #E5CC80\n  heirloom: #00CCff\n}\n\nfor $quality, $color in $qualities {\n  .{$quality} {\n    color: $color !important\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poor": `POL4Hpsi7czZn_M1yOTx`,
	"common": `KQ9O7TfrzXwceQgsA6tw`,
	"uncommon": `a9WoVazYs4aLdZ3vM416`,
	"rare": `nPkziMFG4wNKXgGtpHpW`,
	"epic": `spiZKbwyNh12LsxlNFqe`,
	"legendary": `DNT33FHVeD91KE0o_tDe`,
	"artifact": `T5tTtTQ7QhKJf5BLr6Zx`,
	"heirloom": `JPD6vHxWVsZ2MXSQp0U3`
};
export default ___CSS_LOADER_EXPORT___;
