// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LxNgkt4Gojxyby_E4k7E{vertical-align:middle}.l4jd59oob2uMeQVe7F4b{display:inline-block;overflow:hidden}.l4jd59oob2uMeQVe7F4b canvas{display:block;max-width:100%;max-height:100%}`, "",{"version":3,"sources":["webpack://./src/components/images/GameImage/index.styl"],"names":[],"mappings":"AAAA,sBACE,qBAAgB,CAGlB,sBACE,oBAAS,CACT,eAAU,CAEV,6BACE,aAAS,CACT,cAAW,CACX,eAAY","sourcesContent":[".image {\n  vertical-align: middle\n}\n\n.asBackground {\n  display: inline-block\n  overflow: hidden\n\n  canvas {\n    display: block\n    max-width: 100%\n    max-height: 100%\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `LxNgkt4Gojxyby_E4k7E`,
	"asBackground": `l4jd59oob2uMeQVe7F4b`
};
export default ___CSS_LOADER_EXPORT___;
