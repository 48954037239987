// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.mFIRaC8vAlG_399ERU0I,th.mFIRaC8vAlG_399ERU0I{color:rgba(198,198,198,0.5)}`, "",{"version":3,"sources":["webpack://./src/components/core/Table/columns/PlaceholderColumn/index.styl"],"names":[],"mappings":"AACE,gDACE,2BAAO","sourcesContent":["td, th {\n  &.placeholder {\n    color: rgba(#C6C6C6, .5)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `mFIRaC8vAlG_399ERU0I`
};
export default ___CSS_LOADER_EXPORT___;
