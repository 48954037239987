// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XkUSlQ2c3bCr0ZV7033g{cursor:pointer}.XkUSlQ2c3bCr0ZV7033g .g2nbJflCE8JaZkY6cySv:hover{text-decoration:underline}.XkUSlQ2c3bCr0ZV7033g.ZgrO21alIRJpkEK_NmlA .g2nbJflCE8JaZkY6cySv{color:#fff;font-weight:bold}.XkUSlQ2c3bCr0ZV7033g.ZgrO21alIRJpkEK_NmlA .g2nbJflCE8JaZkY6cySv:hover{text-decoration:none}.XkUSlQ2c3bCr0ZV7033g .d9Aziz8oa1Ke64xuHqqS{opacity:.5}`, "",{"version":3,"sources":["webpack://./src/components/entities/Location/Label/index.styl"],"names":[],"mappings":"AAAA,sBACE,cAAQ,CAER,kDACE,yBAAiB,CAGnB,iEACE,UAAO,CACP,gBAAa,CAEb,uEACE,oBAAiB,CAIrB,4CACE,UAAS","sourcesContent":[".label {\n  cursor: pointer\n\n  .name:hover {\n    text-decoration: underline\n  }\n\n  &.current .name {\n    color: #FFF\n    font-weight: bold\n\n    &:hover {\n      text-decoration: none\n    }\n  }\n\n  .count {\n    opacity: .5\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `XkUSlQ2c3bCr0ZV7033g`,
	"name": `g2nbJflCE8JaZkY6cySv`,
	"current": `ZgrO21alIRJpkEK_NmlA`,
	"count": `d9Aziz8oa1Ke64xuHqqS`
};
export default ___CSS_LOADER_EXPORT___;
