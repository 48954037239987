// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mq1zdWmq9Pg78WQ8vrm_{display:flex;align-items:center;justify-content:center;border-radius:50%;border:1px solid #000;background:#fc0;box-shadow:rgba(0,0,0,0.75) 1px 1px}.Mq1zdWmq9Pg78WQ8vrm_:after{content:'';display:block;width:3px;height:3px;border-radius:50%;background:#261f00}`, "",{"version":3,"sources":["webpack://./src/components/core/GameMap/Pin/index.styl"],"names":[],"mappings":"AAAA,sBACE,YAAS,CACT,kBAAa,CACb,sBAAiB,CACjB,iBAAe,CACf,qBAAQ,CACR,eAAY,CACZ,mCAAY,CAEZ,4BACE,UAAS,CACT,aAAS,CACT,SAAO,CACP,UAAQ,CACR,iBAAe,CACf,kBAAY","sourcesContent":[".pin {\n  display: flex\n  align-items: center\n  justify-content: center\n  border-radius: 50%\n  border: 1px solid #000\n  background: #FFCC00\n  box-shadow: rgba(0, 0, 0, .75) 1px 1px\n\n  &:after {\n    content: ''\n    display: block\n    width: 3px\n    height: 3px\n    border-radius: 50%\n    background: darken(#FFCC00, 85%)\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin": `Mq1zdWmq9Pg78WQ8vrm_`
};
export default ___CSS_LOADER_EXPORT___;
