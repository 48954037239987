// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zROkVNXoQctA6e5w3v9Z{max-width:2em}.M0jWxj8PhElA5Ylqx64i{max-width:1.3em;max-height:1.3em}.NSLUCNZ_hzTzjMulORu6 canvas{transform-origin:top left}.Els7lbVnGI0rIbGsPGZt canvas{transform:scale(4) translate(0,0)}.xq6FepcEibSpMXnKUGAB canvas{transform:scale(4) translate(0,-50.2%)}.PvBZbelkVOZSJwrJKKzT canvas{transform:scale(4) translate(0,-25.1%)}.ZUnTwxDrBwjGeIXaqH_z canvas{transform:scale(4) translate(-49.4%,0)}.xgs47Fy9z8gvJgBaWSWk canvas{transform:scale(4) translate(-49.4%,-25.1%)}.V0X9eRFo4jNEXtwZgRwp canvas{transform:scale(4) translate(-24.7%,-50.2%)}.E7C1U8VvL7oWQ1OtNf03 canvas{transform:scale(4) translate(-24.7%,-25.1%)}.bWQLytXbZSBx_pHTNXnw canvas{transform:scale(4) translate(-24.7%,0)}.cVDcYr4e2eOD_Dm7Awuw canvas{transform:scale(4) translate(-74.3%,-25.1%)}.TAgy9OFAi1zmCRGiKc_0 canvas{transform:scale(4) translate(-74.3%,0)}`, "",{"version":3,"sources":["webpack://./src/components/entities/Class/Icon/index.styl"],"names":[],"mappings":"AAAA,sBACE,aAAW,CAGb,sBACE,eAAW,CACX,gBAAY,CAGd,6BACE,yBAAkB,CAiBlB,6BACE,iCAAW,CADb,6BACE,sCAAW,CADb,6BACE,sCAAW,CADb,6BACE,sCAAW,CADb,6BACE,2CAAW,CADb,6BACE,2CAAW,CADb,6BACE,2CAAW,CADb,6BACE,sCAAW,CADb,6BACE,2CAAW,CADb,6BACE,sCAAW","sourcesContent":[".normal {\n  max-width: 2em\n}\n\n.small {\n  max-width: 1.3em\n  max-height: 1.3em\n}\n\n.icon canvas {\n  transform-origin: top left\n}\n\n$classes = {\n  warrior: translate(0, 0)\n  paladin: translate(0, -50.2%)\n  hunter: translate(0, -25.1%)\n  rogue: translate(-49.4%, 0)\n  priest: translate(-49.4%, -25.1%)\n  deathknight: translate(-24.7%, -50.2%)\n  shaman: translate(-24.7%, -25.1%)\n  mage: translate(-24.7%, 0)\n  warlock: translate(-74.3%, -25.1%)\n  druid: translate(-74.3%, 0)\n}\n\nfor $class, $translate in $classes {\n  .{$class} canvas {\n    transform: scale(4) $translate\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"normal": `zROkVNXoQctA6e5w3v9Z`,
	"small": `M0jWxj8PhElA5Ylqx64i`,
	"icon": `NSLUCNZ_hzTzjMulORu6`,
	"warrior": `Els7lbVnGI0rIbGsPGZt`,
	"paladin": `xq6FepcEibSpMXnKUGAB`,
	"hunter": `PvBZbelkVOZSJwrJKKzT`,
	"rogue": `ZUnTwxDrBwjGeIXaqH_z`,
	"priest": `xgs47Fy9z8gvJgBaWSWk`,
	"deathknight": `V0X9eRFo4jNEXtwZgRwp`,
	"shaman": `E7C1U8VvL7oWQ1OtNf03`,
	"mage": `bWQLytXbZSBx_pHTNXnw`,
	"warlock": `cVDcYr4e2eOD_Dm7Awuw`,
	"druid": `TAgy9OFAi1zmCRGiKc_0`
};
export default ___CSS_LOADER_EXPORT___;
