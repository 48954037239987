// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k1yEkUVuhmtU3Jx6XlAY{max-height:1.2em;margin-right:.1em}`, "",{"version":3,"sources":["webpack://./src/components/entities/Side/Icon/index.styl"],"names":[],"mappings":"AAAA,sBACE,gBAAY,CACZ,iBAAc","sourcesContent":[".icon {\n  max-height: 1.2em\n  margin-right: .1em\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `k1yEkUVuhmtU3Jx6XlAY`
};
export default ___CSS_LOADER_EXPORT___;
