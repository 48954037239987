// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/bullet.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("images/bullet-current.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xTwnm8DwmomvTdWV1n03{list-style-position:outside;list-style-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});padding-left:20px}.xTwnm8DwmomvTdWV1n03 .yFZDzQM8ZIeE9ANZMRcG{padding:.5em 0}.xTwnm8DwmomvTdWV1n03 .yFZDzQM8ZIeE9ANZMRcG.sg25NxqgCGhcWuReewQg{list-style-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}.xTwnm8DwmomvTdWV1n03 .yFZDzQM8ZIeE9ANZMRcG:last-child{padding-bottom:.25em}.xTwnm8DwmomvTdWV1n03 .xTwnm8DwmomvTdWV1n03{margin-top:1em}`, "",{"version":3,"sources":["webpack://./src/components/core/List/index.styl"],"names":[],"mappings":"AAAA,sBACE,2BAAqB,CACrB,wDAA2C,CAC3C,iBAAc,CAEd,4CACE,cAAS,CAET,iEACE,wDAAmD,CAGrD,uDACE,oBAAgB,CAIpB,4CACE,cAAY","sourcesContent":[".list {\n  list-style-position: outside\n  list-style-image: url('./images/bullet.png')\n  padding-left: 20px\n\n  .item {\n    padding: .5em 0\n\n    &.current {\n      list-style-image: url('./images/bullet-current.png')\n    }\n\n    &:last-child {\n      padding-bottom: .25em\n    }\n  }\n\n  .list {\n    margin-top: 1em\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `xTwnm8DwmomvTdWV1n03`,
	"item": `yFZDzQM8ZIeE9ANZMRcG`,
	"current": `sg25NxqgCGhcWuReewQg`
};
export default ___CSS_LOADER_EXPORT___;
